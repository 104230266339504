<template>
    <v-card class="pa-3">
        <h1>{{ $t("interests.interests") }}</h1>
            <v-data-table
                lang="nl-NL"
                :loading="(interests.length < 1 && tags.length < 1)"
                v-model="interests"
                :headers="headers"
                :footer-props="{
                    'items-per-page-options': [10, 20, 40, 50, 100, -1]
                }"
                :items-per-page="20"
                :items="(editing) ? tags : interests"
                item-key="tag"
                class="elevation-1"
                :search="search"
                :show-select="(editing)"
                :no-data-text="$t('interests.noInterestsFound')"
                :no-results-text="$t('interests.noResultsFound')"
            >
                <template v-slot:top v-if="editing">
                    <v-text-field
                        v-model="search"
                        :label="$t('interests.search')"
                        class="mx-4"
                    ></v-text-field>
                </template>
            </v-data-table>
            <br>
        <v-btn v-on:click="submit">{{ (editing) ? $t("interests.save") : $t("interests.edit")}}</v-btn>
        <v-snackbar v-model="confirmSubmit" >
            {{ confirmSubmitText }}

            <template v-slot:action="{ attrs }">
                <v-btn
                color="blue darken-3"
                v-bind="attrs"
                @click="confirmSubmit = false"
                >
                {{ $t("interests.close") }}
                </v-btn>
            </template>
        </v-snackbar>
    </v-card>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { ILLI_API_SERVER_URL } from '../config'
export default Vue.extend({
    name: "interests",
    components: {
        //
    },
    data () {
        return {
            confirmSubmit: false,
            confirmSubmitText: "",
            search: '',
            interests: [],
            tags: [],
            editing: false,
        }
    },
    computed: {
        headers () {
            if(this.editing) {
                return [{
                    text: this.$t("interests.tagsToChooseFrom"),
                    align: 'start',
                    sortable: true,
                    value: 'tag',
                },{
                    text: this.$t("interests.tagCategory"), 
                    value: 'category'
                }]
            } else {
                return [{
                    text: this.$t("interests.interestsFromResidents"),
                    align: 'start',
                    sortable: true,
                    value: 'tag',
                }]
            }
        },
        ...mapGetters({
            system: "system/system",
        })
    },
    mounted () {
        axios
        .get(`${ILLI_API_SERVER_URL}/tags`)
        .then(response => (this.tags = response.data.tags))
        axios
        .get(`${ILLI_API_SERVER_URL}/interests/` + this.system.id)
        .then(response => {
            this.interests = response.data.interests
        })
    },
    methods: {
        submit() {
            if(this.editing) {
                axios
                .post(`${ILLI_API_SERVER_URL}/interests/` + this.system.id, { "tags" : this.interests.map(tag => tag.tag) })
                .then(response => {
                    this.confirmSubmitText = (response.status === 200) ? `${this.$t("interests.saved")}` : `${this.$t("interests.notSaved")}`
                    this.confirmSubmit = true
                    this.editing = !this.editing
                    this.search = ""
                })
                .catch(error => console.error(error))
            } else {
                this.editing = !this.editing
                this.search = ""
                console.log(this.editing)
            }
        }
    }
})
</script>